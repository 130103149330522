import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button, Select } from "antd"
import { ModalNewLead } from "../../Modals/ModalNewLead/ModalNewLead"

const { Option } = Select

const SectionWeTalkYou = ({ i_am_options }) => {
  const { t } = useTranslation()

  const [optionSelected, setOptionSelected] = useState()
  const [iWantSelected, setIWantSelected] = useState()
  const [contact, setContact] = useState(false)

  useEffect(() => {
    if (!optionSelected) {
      setOptionSelected(i_am_options[0])
      setIWantSelected(i_am_options[0].i_want[0])
    }
  }, [optionSelected])

  const handleChange = value => {
    const option = i_am_options.find(item => item.key === value)
    setOptionSelected(option)
    setIWantSelected(option.i_want[0])
  }

  const changeIWantSelected = value => {
    const iWantOption = optionSelected.i_want.find(item => item.key === value)
    setIWantSelected(iWantOption)
  }

  return (
    <div className="content">
      <div className="contact-cont">
        <h1 className="we-talk-to-you__title">{t("general.we_talk_you")}</h1>
        <div data-aos="fade-up" className="we-talk-to-you__form-contact">
          <div className="form-item">
            <p>{t("general.i_am")}</p>
            <Select
              defaultValue={i_am_options && i_am_options[0].key}
              onChange={handleChange}
            >
              {i_am_options?.map(option => (
                <Option key={option.key} value={option.key}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-item">
            <p>{t("general.i_want")}</p>
            {optionSelected && (
              <Select value={iWantSelected.key} onChange={changeIWantSelected}>
                {optionSelected.i_want.map(item => (
                  <Option key={item.key} value={item.key}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <div className="cont-btn">
            <Button
              onClick={() => setContact(true)}
              className="btn btn--primary"
            >
              {t("button.contact")}
            </Button>
          </div>
          {contact &&
            <ModalNewLead
              handlerClose={(newState) => setContact(newState)}
              visible={contact}
              keyForm={optionSelected?.key}
              params={{ serviceInterest: iWantSelected }}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default SectionWeTalkYou
