import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useInView } from "react-intersection-observer"

import { navigatePage } from "../../../@common/utils/navigate"
import { Multimedia } from "../../Multimedia/Multimedia"
import { getOS, isMacOS } from "../../../@common/utils/getOS"

export const SectionLogistics = ({
  media,
  title_simple_logistics,
  description_simple_logistics,
  title_allies,
  allies,
  button_simple_logistics,
}) => {

  const [banner] = useInView({ threshold: 0 })
  const [os, setOS] = useState()

  useEffect(() => {
    setOS(getOS())
  }, [])
  
  return (
    <div 
      className={`SectionLogistics SectionLogistics--background-${os !== 'window' ? 'macos': 'window'}`}
    >
      <div className={`SectionLogistics__container SectionLogistics__container--background-${os}`}>
        <div className="SectionLogistics__container--left">
          <div className="SectionLogistics__container--left__text">
            <span className="title">{title_simple_logistics}</span>
            <p className="paragraph">
              {description_simple_logistics?.description_simple_logistics}
            </p>
            <span className="subtitle">{title_allies}</span>
          </div>
          <div className="SectionLogistics__container--left__icons">
            {allies?.map((item, i) => (
              <div key={i} className="cont-img">
                <img className="img" src={item.photo} alt="icons" />
              </div>
            ))}
          </div>
          <div className="cont-btn">
            <Button
              className="btn btn--demo"
              onClick={() => navigatePage("/revolutionizes-deliveries")}
            >
              {button_simple_logistics?.label}
            </Button>
          </div>
        </div>
        <section ref={banner} className="SectionLogistics__container--rigth">
          <Multimedia className="van" src={media} />
        </section>
      </div>
      {/* <div className="withe" /> */}
    </div>
  )
}
