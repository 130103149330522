import React, { useState, useEffect, useRef } from "react"
import { Progress } from "antd"
import { useInView } from "react-intersection-observer"
import Slider from "react-slick"
import AliceCarousel from "react-alice-carousel"
import { useTranslation } from "react-i18next"
import "react-alice-carousel/lib/alice-carousel.css"

import { useStickyroll } from "@stickyroll/hooks"
import CountNumber from "../Cards/CardsCount/CardsCount"
import { Multimedia } from "../Multimedia/Multimedia"

const SectionTabs = props => {
  const { t } = useTranslation()
  const {
    tabsKeys,
    section: sectionProps,
    buttons,
    sectionTitle,
    typeMultimedia,
    backgroundRight,
    itemsInLines,
    items,
    index,
    footerMap,
    sectionTabsProps
  } = props
  const [wrapper, { height, pageIndex, progress }] = useStickyroll({
    pages: tabsKeys,
    factor: 1,
  })

  const [currentIndex, setCurrentIndex] = useState(3)
  const [tabSelected, setTabSelected] = useState()
  const [sectionTabs, inSectionTabs] = useInView({ threshold: 0 })
  const [slide] = useState(false)
  const slickRef = useRef(null)

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    speed: 200,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    beforeChange: (current, next) => setCurrentIndex(next),
    customPaging: i => (
      <div className="tabs-head">
        <p onClick={() => navigate(i)}>{tabsKeys && props[tabsKeys[i]].name}</p>
      </div>
    ),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          vertical: false,
          verticalSwiping: false,
        }
      }
    ]
  }

  const navigate = i => {
    if (!(typeof window !== "undefined" && window.scrollTo)) {
      return
    }
    let currentHeight
    const logisticsHeight = document.querySelector(".SectionLogistics")
      .offsetTop
    if (index === 0) {
      currentHeight = logisticsHeight - logisticsHeight * 0.2
      currentHeight = (currentHeight / 3) * (i + 1)
    } else {
      const trustHeight = document.querySelector(".TrustSection").offsetTop
      if (i === 0) {
        currentHeight = logisticsHeight + logisticsHeight * 0.2
      } else if (i === 1) {
        currentHeight = (logisticsHeight + trustHeight) / 2
      } else {
        currentHeight = (trustHeight - trustHeight * 0.1) - 805
      }
    }
    window.scrollTo(0, currentHeight)
  }

  useEffect(() => {
    if (slickRef !== null) {
      slickRef.current.slickGoTo(pageIndex)
    }
    setTabSelected(items[pageIndex])
  }, [pageIndex, slickRef])

  const getProgess = () => {
    const increment = (100 / items.length) * pageIndex
    const currentProgress = (progress * 100) / 3
    return currentProgress + increment
  }

  const imageStatic = items && items[0]?.image

  return (
    <div ref={wrapper} className={`stickyContainer  ${footerMap && 'stickyContainer--map'}`} style={{ paddingBottom: index == 0 ? '0em' : 0, height }}>
      <div className="stickyScrollTabs">
        <div
          className={`sectionTabs sectionTabs--${sectionTabsProps} ${slide && "sticky"}  ${currentIndex === 2 && "auto"
            }`}
        >
          <div className="sectionTabs--content" style={{ marginTop: '60px' }}>
            <div className="caroucel caroucel--vertical">
              <div className="header" />
              <div className="Progress">
                <Progress percent={getProgess()} showInfo={false} />
              </div>
              <h3 className="caroucel--category">{sectionTitle}</h3>
              <Slider ref={sectionTabs} ref={slickRef} {...settings}>
                {items?.map((item) =>
                  item ? (
                    <div key={index} className="item">
                      <h3 className="item--title">{item.title}</h3>
                      <p className={`item--text item--text-${index}`}>
                        {item.description.description}
                      </p>
                      {item.metada && (
                        <div className="animate__animated animate__fadeInLeft">
                          <CountNumber accountant={item.metada} />
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </Slider>

              <div
                className={`cont-btn up-${index} ${buttons.length < 1 && "none-btn"} ${sectionProps === "sectionTwo" && "cont-btn--Two"
                  }`}
              >
                {buttons?.map(button => button)}
              </div>
            </div>
            <div
              className={`caroucel car-res ${typeMultimedia !== "carousel" && "caroucel--sectionTwo"
                }`}
            >
              <>
                {/* {backgroundRight && <div className={backgroundRight} />} */}
                {backgroundRight && <div className={backgroundRight} />}
                {typeMultimedia === "carousel" && (
                  <AliceCarousel
                    slideToIndex={currentIndex}
                    duration={250}
                    mouseTrackingEnabled={false}
                  >
                    {items?.map((item, index) => (
                      <div key={index} className="caroucel--img">
                        <div className="video">
                          <Multimedia
                            className="videoanimation"
                            src={item.image}
                            interval={4000}
                          />
                        </div>
                      </div>
                    ))}
                  </AliceCarousel>
                )}
                {typeMultimedia === "image_static" && (
                  <>
                    <div className="rigth__svg-prof">
                      <div
                        className={`svgRight ${inSectionTabs && "animated-line"
                          }`}
                      >
                        <svg className="svg" width="222px" height="61px">
                          <path
                            fill="transparent"
                            d="M175.991 1.48828H45.9907L1.49072 66.9883"
                            stroke="#FF7A57"
                            strokeWidth="1"
                            className="path-right"
                          />
                        </svg>
                        <h3 className="css-typing__title css-typing__title-r">
                          {itemsInLines[0]}
                        </h3>
                      </div>
                    </div>
                    <div className="rigth__svg-prof">
                      <div
                        className={`svgRight svgRight--two ${inSectionTabs && "animated-line"
                          }`}
                      >
                        <svg className="svg" width="120px" height="40px">
                          <path
                            fill="transparent"
                            d="M175.991 1.48828H45.9907L1.49072 66.9883"
                            stroke="#FF7A57"
                            strokeWidth="1"
                            className="path-right"
                          />
                        </svg>
                        <h3 className="css-typing__title css-typing__title-r title-r">
                          {itemsInLines[1]}
                        </h3>
                      </div>
                    </div>
                    {imageStatic && (
                      <img
                        className="weLifters"
                        src={imageStatic}
                        alt="weAreLifters"
                      />
                    )}
                  </>
                )}
              </>
              {tabSelected?.comment && (
                <div
                  className={`card--coment ${inSectionTabs &&
                    "animate__animated animate__fadeIn in-animate"
                    }`}
                >
                  <img
                    src={tabSelected.comment.photo}
                    alt="avatar"
                    className="avatar"
                  />
                  <div>
                    <h2>{tabSelected.comment.nombreCliente}</h2>
                    <p>{tabSelected.comment.comment.comment}</p>
                    {tabSelected.comment.redirect && (
                      <a
                        target="_blank"
                        href={tabSelected.comment.redirect.link.link}
                        rel="noopener noreferrer"
                      >
                        {t("button.read_more")}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SectionTabs
