import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import CountUp from "react-countup"

const CountNumber = ({ accountant: accountantContentful }) => {
  const isInt = n => n % 1 === 0

  const [sectionCounts, inSectionCounts] = useInView({ threshold: 0 })
  const [isAnimationNumbers, setAnimationNumbers] = useState()
  const [accountant, setAccountant] = useState()

  useEffect(() => {
    if (inSectionCounts) {
      setTimeout(() => setAnimationNumbers(true), 4500)
    }
  }, [inSectionCounts])

  useEffect(() => {
    setAccountant(
      accountantContentful.map(item => JSON.parse(item.internal.content))
    )
  }, [])

  return (
    <div className="CardsCount" ref={sectionCounts}>
      {accountant?.map((item, index) => (
        <div className="card" key={index}>
          <div className="accountant-content">
            {item.title && <p className="title">{item.title}</p>}
            <div className="CountUp">
              <span>{item.digitStart}</span>
              {inSectionCounts && (
                <CountUp
                  suffix={item.digitEnd}
                  start={isAnimationNumbers ? item.number : 0}
                  duration={4}
                  end={item.number}
                  decimals={isInt(item.number) ? 0 : 1}
                />
              )}
            </div>
            <p className="des-title">{item.name}</p>
          </div>
          {index + 1 !== accountant.length && <div className="divider" />}
        </div>
      ))}
    </div>
  )
}

export default CountNumber
