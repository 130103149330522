import React, { useState } from "react"

import shadow from "../../../assets/images/shadow.png"
import { Multimedia } from "../../Multimedia/Multimedia"

const itemsKeys = ["we_connection", "we_execute", "we_inspire", "we_plan"]

export const SectionStrategies = props => {

  const [stateCard, setStateCard] = useState()

  const handlerClickItem = (index) => {
    if(stateCard !== undefined && stateCard === index)
      setStateCard()
    else
      setStateCard(index)
  }

  return (
    <div className="SectionStrategies">
      <section className="SectionStrategies__content-card">
        {itemsKeys?.map((key, index) => (
          <div
            onClick={() => handlerClickItem(index)}
            key={index}
            className={`SectionStrategies__content-card__card ${
              stateCard === index ? "active" : "inactive"
            } ${!stateCard && "visible"}`}
          >
            <div className="SectionStrategies__content-card__card--images">
              <div className="head-gray"></div>
              <div className="tweet-box">
                <Multimedia 
                  className="video-strategies"
                  id={key}
                  src={props[key].image} 
                  hover
                />
              </div>
              <img
                className="shadow shadow--like"
                src={shadow}
                alt="shadow"
              />
            </div>
            <div className="SectionStrategies__content-card__card--body">
              <h2 className="title">{props[key]?.title}</h2>
              <p className="paragraph">{props[key]?.description.description}</p>
            </div>
            <div className="SectionStrategies__content-card__card--sub-title">
              <span>{props[key]?.name}</span>
              <span className="x">x</span>
              <div className="line" />
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}
