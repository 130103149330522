import React, { useState } from "react"
import { Button, Select } from "antd"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useTranslation } from "react-i18next"
import { CaretDownOutlined } from "@ant-design/icons"

import copy from "../../assets/images/copy.png"
import { Result } from "./components/Result"
import { Documentation } from "./components/Documentation"
import { navigatePage } from "../../@common/utils/navigate"

const { Option } = Select

export const SmartConnection = ({ title, description, servicesApi }) => {
  const { t } = useTranslation()

  const listItems = [
    { id: 1, name: t("general.description") },
    { id: 2, name: t("general.documentation") },
    { id: 3, name: t("general.result") },
  ]

  const [tabSelected, setTabSelected] = useState(1)
  const [serviceSelected, setServiceSelected] = useState(0)

  const handleApiService = id => {
    setServiceSelected(id)
    setTabSelected(1)
  }

  const redirectFullDocumentation = () => {
    window.open('https://optimizerv330.docs.apiary.io/#reference/0/optimizer/create-new-optimization', '_blank')
  }

  if (!servicesApi) return null

  return (
    <div className="SmartConnection">

      <h1 data-aos="fade-up" className="title">{title}</h1>
      <span data-aos="fade-up" className="sub-title">
        {description?.description_liftit_connection}
      </span>
      <div  className="SmartConnection__content">
        <div className="SmartConnection__content--menu">
          <div className="SmartConnection__background"></div>
          <div className="menu-btns">
            {servicesApi.map((api, index) => (
              <Button
                key={index}
                onClick={() => handleApiService(index)}
                className={`btn ${serviceSelected === index ? "btn-orange" : "btn-view"
                  }`}
              >
                {api.name}
              </Button>
            ))}
          </div>
          <div className="cont-btn">
            <Button className="btn btn--primary" onClick={redirectFullDocumentation}>
              Full documentation
            </Button>
          </div>
        </div>
        <div className="SmartConnection__content--post">
          <div className="head">
            <div className="head__api">
              <div className="head__api--meth">
                <span>{servicesApi[serviceSelected]?.method_http}</span>
              </div>
              <span className="head__api--link">
                {servicesApi[serviceSelected]?.url}
              </span>
              <img className="copy-head" src={copy} alt="copy-paste" />
            </div>
            <div className="head--btn cont-btn">
              <Button
                className="btn btn--demo"
                onClick={() => setTabSelected(3)}
              >
                {t("general.call_resource")}
              </Button>
            </div>
          </div>
          <div className="SmartConnection__content--items">
            {listItems?.map((data, index) => (
              <div
                key={index}
                onClick={() => setTabSelected(data.id)}
                className={`item ${tabSelected === data.id ? "item--active" : "item--inactive"
                  }`}
              >
                {data.name}
              </div>
            ))}
          </div>
          <CaretDownOutlined className="icon-select" />
          <div className="SmartConnection__content--items-select">
            <Select
              className="selectt"
              defaultValue={listItems[0]?.id}
              onChange={setTabSelected}
            >
              {listItems?.map((data, index) => (
                <Option
                  className="option-select-smart"
                  key={index}
                  value={data.id}
                >
                  {data.name}
                </Option>
              ))}
            </Select>
          </div>
          {tabSelected === 1 && (
            <div className="item-content section-pint animate__animated animate__fadeIn">
              {documentToReactComponents(
                servicesApi[serviceSelected]?.description.json
              )}
            </div>
          )}
          {tabSelected === 2 && (
            <Documentation apiService={servicesApi[serviceSelected]} />
          )}
          {tabSelected === 3 && (
            <Result apiService={servicesApi[serviceSelected]} />
          )}
        </div>
      </div>
    </div>
  )
}
