import React, { useState } from "react"
import { Button } from "antd"

import Slider from "react-slick"

export const SectionTrustDeliveries = ({
  title_cases_success,
  success_stories,
}) => {
  const [caseSelect, setCaseSelect] = useState(0)
  const [slider, setISlider] = useState(true)

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: slider,
    speed: 150,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 700,
        settings: { rows: 2, slidesToShow: 2 },
      },
    ],
  }

  const handleClickStories = (_case, index) => {
    if (caseSelect === index + 1) {
      setCaseSelect(0)
      setISlider(true)
    } else {
      setCaseSelect(index + 1)
      setISlider(false)
    }
  }

  return (
    <div className={`TrustSection ${caseSelect > 0 && "TrustSection-active"}`}>
      <div className="content">
        <div className="content__head">
          <h2 className="TrustSection--sub-title">{title_cases_success?.h2}</h2>
          <h1 className="TrustSection--title">{title_cases_success?.h1}</h1>
        </div>
      </div>
      <div className="caroucel">
        <Slider {...sliderSettings}>
          {success_stories?.map((_cases, index) => (
            <div className="thumb-caroucel" key={index}>
              <div className="caroucel-items">
                <div
                  key={index}
                  onClick={() => handleClickStories(_cases, index)}
                  className={`item item-${index + 1} ${
                    caseSelect === index + 1 && "item--active"
                  }`}
                >
                  <img src={_cases.logo} alt="logo-company" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {caseSelect > 0 &&
        success_stories?.map((_case, index) => (
          <div className="description--cont" key={index}>
            {caseSelect === index + 1 && (
              <div
                className="description animate__animated animate__fadeIn"
                style={{
                  backgroundImage: `linear-gradient(88.87deg, rgba(0, 0, 0, 0.66) 20.69%, rgba(0, 0, 0, 0.25) 98.83%), url(${_case.banner}), url(${_case.image_bluer})`,
                }}
              >
                <img
                  className="description__img"
                  src={_case.logo}
                  alt="company"
                />
                <p className="description__logotype">{_case.subtitle}</p>
                <p className="description__text">
                  {_case.description?.description}
                </p>
                <div className="cont-btn">
                  <Button className="btn btn--primary">
                    {_case.link?.label}
                  </Button>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}
