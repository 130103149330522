import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DownOutlined, UpOutlined } from "@ant-design/icons"

import copy from "../../../assets/images/copy.png"

export const Documentation = ({ apiService }) => {
  const { t } = useTranslation()

  const [showAttributes, setShowAttributes] = useState(false)
  const [header, setHeader] = useState()

  useEffect(() => {
    if (apiService?.header)
      setHeader(JSON.parse(apiService.header.internal?.content))
  }, [apiService])

  const openAttributes = () => {
    setShowAttributes(!showAttributes)
  }

  return (
    <div className="item-content animate__animated animate__fadeIn">
      <div className="item-content__head">
        <h2 className="item-content--title item-content--title--two">
          {t("general.request")}
        </h2>
        <div className="center">
          <img src={copy} alt="copy-paste" />
        </div>
      </div>
      <div className="item-content--description">
        <div className="item-content__select">
          <h2>{t("general.attributes")}</h2>
          <div className="body-select" onClick={() => openAttributes()}>
            <span>
              {t("button.watch")} {apiService?.params?.length}{" "}
              {t("general.attributes").toLowerCase()}
            </span>
            {!showAttributes && (
              <DownOutlined className="animate__animated animate__fadeIn" />
            )}
            {showAttributes && (
              <UpOutlined className="animate__animated animate__fadeIn" />
            )}
          </div>
        </div>
        {showAttributes && (
          <div className="content--services">
            {apiService?.params?.map((param, index) => (
              <Fragment key={index}>
                <div className="content--services--type">
                  <span>{param.key}</span>
                  <p className="required">{param.required && "required"}</p>
                </div>
                <div className="content--services--des">
                  <span className="type-carcter">{param.type}</span>
                  <span>{param.description}</span>
                </div>
              </Fragment>
            ))}
          </div>
        )}
        <div className="divider" />
        {header &&
          <div className="result">
            <h2>{t("general.header")}</h2>
            {Object.keys(header).map((item, index) => (
              <span key={index}>
                {item}: {header[item]}
              </span>
            ))}
          </div>
        }
      </div>
    </div>
  )
}
