export const generateCode = code => {
  let resultsCode = []
  let lineNumber = 1

  if (Array.isArray(code)) {
    resultsCode.push({
      type: "symbol",
      lineNumber: `0${lineNumber}`,
      value: "[",
    })
    code.forEach(item => {
      const [jsonParser, newLineNumber] = generateJSONCode(item, lineNumber)
      lineNumber = newLineNumber
      resultsCode = [...resultsCode, ...jsonParser]
    })
    lineNumber += 1
    resultsCode.push({ type: "symbol", lineNumber, value: "]" })
  } else {
    const [jsonParser, newLineNumber] = generateJSONCode(code, lineNumber)

    lineNumber = newLineNumber
    resultsCode = [...resultsCode, ...jsonParser]
  }

  return resultsCode
}

const generateJSONCode = (code, lineNumber) => {
  const resultsCode = []
  lineNumber += 1

  resultsCode.push({
    type: "symbol",
    lineNumber: (lineNumber < 10 ? "0" : "") + lineNumber,
    value: "{",
  })

  Object.keys(code).forEach(key => {
    lineNumber += 1
    resultsCode.push({
      type: "attribute",
      lineNumber: (lineNumber < 10 ? "0" : "") + lineNumber,
      key,
      value: code[key],
    })
  })

  lineNumber += 1

  resultsCode.push({
    type: "symbol",
    lineNumber: (lineNumber < 10 ? "0" : "") + lineNumber,
    value: "}",
  })

  return [resultsCode, lineNumber]
}
