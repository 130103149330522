import React, { useEffect, useState } from "react"
import { Button } from "antd"
import Typist from "react-typist"

import { generateCode } from "../../../@common/utils/generateCodeEditor"

export const Result = ({ apiService }) => {
  const [firstResults, setFirstResults] = useState()
  const [secondResults, setSecondResults] = useState()
  const [isCodeComplete, setCodeComplete] = useState()

  useEffect(() => {
    if (apiService) {
      const responseCode = JSON.parse(apiService.response.internal.content)
      let codeComplete = generateCode(responseCode)
      setSecondResults(codeComplete.slice(12))
      codeComplete = [
        ...codeComplete.slice(0, 12),
        {
          type: "button",
          component: (
            <Button
              key="code-button"
              id="code-button"
              className="code-Typist--btn"
              onClick={showCodeComplete}
            >
              . . .
            </Button>
          ),
        },
      ]
      setFirstResults(codeComplete)
    }
  }, [apiService])

  const showCodeComplete = () => {
    setCodeComplete(true)
    document.getElementById("code-button").hidden = true
  }

  return (
    <div className="item-content animate__animated animate__fadeIn ">
      <div
        style={{ overflow: "auto" }}
        className="item-content--description description--two"
      >
        {firstResults && (
          <div className="code-Typist">
            <ComponentTypist lines={firstResults} />
            {isCodeComplete && <ComponentTypist lines={secondResults} />}
          </div>
        )}
      </div>
    </div>
  )
}

const ComponentTypist = ({ lines }) => {
  return (
    <Typist cursor={{ show: false }} avgTypingDelay={0} stdTypingDelay={0}>
      {lines.map((result, index) => {
        if (result.type === "symbol")
          return (
            <p key={index}>
              {result.lineNumber}
              {"   "}
              <span className="key">
                {result.value}
                {result.value === "}" ? "," : ""}
              </span>
            </p>
          )
        if (result.type === "attribute")
          return (
            <p key={index}>
              {result.lineNumber}
              <span className="name">
                {`"${result.key}": `}
                <span className="result-json">
                  {Number.isNaN(result.value) ? '"' : ""}
                  {result.value.toString()}
                  {Number.isNaN(result.value) ? '"' : ""}
                </span>
              </span>
              ,
            </p>
          )

        return result.component
      })}
    </Typist>
  )
}
